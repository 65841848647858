import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/react/24/solid";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { Apple, EmailOutlined, Padding } from "@mui/icons-material";
import RotatingPrompt from "./RotatingPrompt";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  Container,
  Grid,
  Group,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
  createStyles,
  useMantineTheme
} from "@mantine/core";
import { AppButton } from "src/components/common/Buttons/AppButton";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { SocialAuthButton } from "src/components/common/Buttons/SocialAuthButton";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { clearToken, save, setRole, setToken } from "src/utils/storage";
import { UserRole, token } from "src/enums/User";
import { userRoutes } from "src/navigators/routes/user";
import { useAuth } from "src/hooks/useAuth";
import { adminRoutes } from "src/navigators/routes/AdminRoutes";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { resetUser, updateAdminSettings, updateAppSettings, updateUser } from "src/redux/redux-store";
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { FONT_SIZES } from "src/utils/styles";

const useStyles = createStyles((theme) => ({
  inputField: {
    // padding: rem(14), // Adjust padding as needed
    borderColor: theme.colorScheme == "dark" ? theme.white : theme.black,
  }
}));

const SPECIAL_CHARACTERS = ['-', '!', '@', '#', '$', '%', '=', ':', '?', '.', '/', '|', '~', '>'];

// Convert the special characters to a regex-safe string
const specialCharactersRegex = `[${SPECIAL_CHARACTERS.map(c => '\\' + c).join('')}]`;

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(new RegExp(specialCharactersRegex), "Password must have at least one special character")
    .matches(/\d/, "Password must have at least one number"),
});

const Login: FunctionComponent = () => {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const {login, checkRole} = useAuth()
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useMantineTheme()
  const user = useAppSelector((state) => state.user)
  const backgroundColor = theme.colorScheme == "dark" ? theme.colors.dark[7] : theme.white

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };


  const goToUnwildered = useCallback(() => {
    window.open("https://www.unwildered.co.uk/")
  }, [])

  const logIn = useCallback(async (values: any) => {
      setLoading(true)
      try{
        let trial_expired;
        const response = await authService.logIn(values.email, values.password)

        if(response.kind == ResponseKind.OK){
          const tokenData = response.data
  
          if(tokenData){
            setToken(tokenData)

            const user = await authService.getUserData()
            if(user.kind == ResponseKind.OK){
              if(user?.data){
                if(user.data.trial_expiry){
                 trial_expired = new Date(user.data.trial_expiry).getTime() < new Date().getTime()
                }
                dispatch(updateUser(user.data))
                const app_settings = await authService.getAppInfo()

                if(app_settings.kind == ResponseKind.OK){
                  if(app_settings.data){
                    dispatch(updateAppSettings(app_settings.data))
                  }
                }
                if(user?.data.role == UserRole.user){
                  setRole(UserRole.user)

                  if(user?.data.paid){
                    navigation(userRoutes.DASHBOARD)
                  }else{
                    if(trial_expired){
                      navigation(userRoutes.PAYMENT)
            
                    }else{
                      navigation(userRoutes.DASHBOARD)
          
                    }
                  }                  
                }else{
                  const admin_config = await authService.getAdminConfig()
                  setRole(UserRole.admin)
                  
                  if(admin_config.kind == ResponseKind.OK){
                    if(admin_config?.data){
                      dispatch(updateAdminSettings(admin_config?.data))
                    }
                  }
                  navigation('/admin')
                }
                login(tokenData)
              }
            }else{
              clearToken()
            }
        
          }else{
            alert("Invalid credentials")
            return
          }
        }else{
          throw "Error"
        }
      }catch(e){
        alert("Invalid credentials")
      }finally{
        checkRole()
        setLoading(false)
      }
  }, []);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: yupResolver(schema),
  });

  useEffect(() => {
    clearToken()
    dispatch(resetUser())
  }, [])

  return (
          <Center maw={"75vh"} h={"100vh"} mx="auto">
            <Stack spacing={"1"} maw={"85%"}>
              <Center>
              <Image onClick={goToUnwildered} src={"/unwildered-logo-horizontalremovebgpreview-1@2x.png"} fit="contain"/>
              </Center>
              <Center>
                <RotatingPrompt />
              </Center>
              <Space h={40} />
              <form onSubmit={form.onSubmit((values) => logIn(values))}>
                <Stack spacing={"sm"}>
                  <TextInput
                  
                    placeholder="johndoe@gmail.com"
                    rightSection={<img src="/mail_box.png" style={{width: rem(14), height: rem(12)}}/>}
                    {...form.getInputProps("email")}
                    className={classes.inputField}
                  />

                  <TextInput
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    rightSection={<img src="/view_password.png" style={{width: rem(16), height: rem(12), cursor: 'pointer'}} onClick={togglePasswordVisibility}/>}
                    {...form.getInputProps("password")}
                    className={classes.inputField}
                  />
                </Stack>
                <Group position="right" mt="xl">
                  <AppButton loading={loading} text="Sign In" type="submit" />
                </Group>
              </form>

              <Center className="flex flex-row justify-center gap-1 mt-4">
              <Text style={{fontSize: FONT_SIZES.h3}} align="center">Don't have an account?</Text>
                <Text style={{fontSize: FONT_SIZES.h3}} component="a" href="/auth/signUp" underline>
                  Sign Up
                </Text>
              </Center>

              <Center className="flex flex-col justify-center">
                <Text style={{fontSize: FONT_SIZES.h3}} component="a" href="/auth/forgot-password" underline>
                  Forgot Password?
                </Text>
              </Center>


              {/* <Group position="apart">
                <SocialAuthButton variant="google" text="Sign In with Google" />
                <SocialAuthButton variant="apple" text="Sign In with Apple" />
              </Group> */}
            </Stack>
        </Center>
    // </Container>
  );
};

export default Login;
